/* added: */
@media screen and (min-width: 1280px) {
  .main-header.white-links nav ul a {
    color: rgb(255, 255, 255);
  }
}
.main-header.white-links nav {
  background: transparent;
  border-color: #fff;
}
header {
  z-index: 10;
  position: sticky;
  top: 0px;
  /* height: 96px; */
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;
}

.main-header.hide {
  top: -10rem;
}
.logout-button {
  display: none;
}
@media (max-width: 1279px) {
  header {
    height: auto;
  }
  .main-header div .dropdown {
    display: none;
  }
  .main-header.header-menu-mobile .logout-button {
    display: block;
  }
}

/* added: */
.main-header > div {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 11px 0px;
  background-color: rgb(24, 24, 24);
  box-sizing: border-box;
}
.logo {
  width: 18px;
  height: auto;
}
.main-header div .dropdown > img {
  border-radius: 100%;
}
.main-header div .dropdown {
  position: absolute;
  right: 32px;
}
.main-header nav {
  display: flex;
  padding: 30px 40px;
  border-bottom: 1px solid;
  align-items: center;
  background: var(--main-bg);
  position: relative;
  z-index: 2;
}
.main-header ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
}
.main-header nav ul {
  display: flex;
  font-size: 12px;
  line-height: 20px;
  gap: 24px;
  font-weight: 400;
}
.main-header nav ul a {
  color: var(--menu-cta-color);
  align-items: center;
  display: flex;
  width: 100%;
}
.main-header nav .menu-mobile > ul > li > a {
  text-transform: uppercase;
}
.main-header .nav-icons {
  margin-left: auto;
  display: flex;
  gap: 25px;
}
.main-header .nav-icons > img,
.main-header .nav-icons .notification-link {
  cursor: pointer;
}
.notification-link span {
  background: rgb(218, 41, 28);
  color: rgb(255, 255, 255);
  padding: 4px 6px;
  border-radius: 8px;
  font-size: 9px;
  position: absolute;
  right: -8px;
  top: 1px;
}
.notification-link {
  position: relative;
}
header:not(.header-menu-mobile) .menu-mobile > ul > li > a:hover,
header:not(.header-menu-mobile) .menu-mobile > ul > li > a.selected {
  border-bottom: 2px solid rgb(218, 41, 28);
}
@media screen and (max-width: 1279px) {
  .main-header.header-menu-mobile nav {
    padding: 24px;
    background: var(--main-bg);
  }
  .main-header nav {
    padding: 24px;
  }
  .header-menu-mobile {
    font-family: ferrari-sans-medium;
    position: fixed;
    min-height: 100%;
    height: 100%;
    overflow: auto;
    width: 100%;
    background: var(--main-bg);
  }
  .menu-mobile li {
    height: 24px;
    display: flex;
  }
  .menu-mobile {
    display: none;
  }
  .main-header .menu-mobile__submenu.selected {
    flex-direction: column;
    position: absolute;
    top: 0px;
    height: 100%;
    z-index: 2;
    justify-content: flex-start;
    padding: 24px 0px;
    box-sizing: border-box;
  }
  .main-header .menu-mobile .menu-mobile__submenu.selected ul.selected {
  }
  .menu-mobile__submenu h2 {
    display: none;
  }
  .main-header.header-menu-mobile nav .menu-mobile ul a {
    color: var(--main-color);
  }
  .main-header.header-menu-mobile nav {
    flex-direction: column;
    align-items: stretch;
    border-bottom: 0;
  }
  .header-menu-mobile .hamburger-icon,
  .header-menu-mobile .nav-icons {
    display: none;
  }
  header.main-header.header-menu-mobile .menu-mobile {
    display: block;
    padding: 24px 0px 32px;
    position: relative;
  }
  header.main-header.header-menu-mobile .menu-mobile:not(.menu-mobile-footer) {
    padding-bottom: 0;
  }
  .main-header.header-menu-mobile ul {
    display: flex;
    flex-direction: column;
    font-size: 16px;
  }
  .main-header.header-menu-mobile .main-header-bar .dropdown {
    display: none;
  }
  .main-header.header-menu-mobile .menu-mobile a img {
    transform: rotate(-90deg);
    margin-left: auto;
    display: block;
    width: 18px;
  }
  .main-header.header-menu-mobile .menu-mobile__first-line {
    display: flex;
  }
  .main-header.header-menu-mobile div.menu-mobile__theme {
    display: flex;
  }
  .main-header.header-menu-mobile .menu-mobile__user-recap {
    display: flex;
  }
  .main-header.header-menu-mobile .menu-mobile-footer ul {
    font-size: 13px;
    text-transform: none;
  }
  header.header-menu-mobile .menu-mobile.menu-mobile-footer {
    border-bottom: 1px solid rgb(212, 210, 210);
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  .main-header.header-menu-mobile .menu-mobile__current-section {
    display: flex;
    color: rgb(112, 112, 112);
    font: 13px ferrari-sans-regular;
    padding: 24px 0px;
    border-bottom: 1px solid rgb(212, 210, 210);
    text-transform: uppercase;
  }
  .main-header.header-menu-mobile .menu-mobile__submenu.selected {
    top: 0px;
    height: 100%;
    z-index: 2;
    background: var(--main-bg);
  }
  .main-header.header-menu-mobile
    .menu-mobile
    .menu-mobile__submenu.selected
    ul {
    display: none;
  }
  .main-header.header-menu-mobile
    .menu-mobile
    .menu-mobile__submenu.selected
    > ul.selected,
  .main-header.header-menu-mobile
    .menu-mobile
    .menu-mobile__submenu.selected
    > ul:first-of-type {
    display: flex;
    width: 100%;
    padding: 24px 0px;
    top: 0px;
    height: 100%;
    box-sizing: border-box;
    background: var(--main-bg);
    z-index: 2;
    position: absolute;
    margin-left: 0;
  }
  .main-header.header-menu-mobile .language-dropdown {
    display: flex;
  }
}
@media screen and (min-width: 1280px) {
  .hamburger-icon {
    display: none;
  }
  .main-header .menu-mobile .menu-mobile__submenu.selected ul {
    display: flex;
    flex-direction: column;
  }
  .main-header
    .menu-mobile
    .menu-mobile__submenu.selected
    ul:not(:first-of-type) {
    opacity: 0;
  }
  .main-header:not(.header-menu-mobile)
    .menu-mobile
    .menu-mobile__submenu.selected
    ul {
    gap: 20px;
  }
  .menu-mobile__submenu > * {
  }
  .main-header:not(.header-menu-mobile) .menu-mobile__submenu.selected {
  }
}
/* added */
.white-links.main-header:not(.header-menu-mobile) nav::before {
  position: absolute;
  width: 100%;
  height: 205px;
  left: 0px;
  top: 0px;
  background: linear-gradient(
    360deg,
    rgba(0, 0, 0, 0) 3.66%,
    rgba(0, 0, 0, 0.5) 52.23%,
    rgba(0, 0, 0, 0.7) 86.51%
  );
  mix-blend-mode: normal;
  opacity: 0.9;
  content: "";
  z-index: -1;
  pointer-events: none;
}
@media (max-width: 1279px) {
  .white-links.main-header:not(.header-menu-mobile) nav::before {
    height: 126px;
  }
}
/* added */
.menu-mobile__close {
  transform: rotate(45deg);
  width: 36px;
  height: 36px;
  cursor: pointer;
  margin-left: auto;
}
.menu-mobile__first-line {
  display: none;
  margin-bottom: 14px;
}
.menu-mobile__close img {
  width: 36px;
  height: 36px;
  cursor: pointer;
}
.menu-mobile__back {
  text-transform: uppercase;
  font-size: 12px;
  cursor: pointer;
}
.menu-mobile__back span {
  margin-left: 10px;
}
.menu-mobile__back img {
  transform: rotate(-180deg);
  width: 36px;
  height: 36px;
}
.menu-mobile__user-recap {
  padding-bottom: 20px;
  border-bottom: 1px solid rgb(212, 210, 210);
  position: relative;
  display: none;
}
.menu-mobile__user-recap img {
  position: relative;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  top: -6px;
}
.menu-mobile__user-recap-wrapper {
  margin-left: 12px;
}
.menu-mobile__user-recap-name {
  font: 16px ferrari-sans-medium;
  margin-bottom: 7px;
}
.menu-mobile__user-recap-action {
  font: 11px ferrari-sans-medium;
  color: rgb(112, 112, 112);
  text-transform: uppercase;
  padding-bottom: 8px;
}
div.menu-mobile__theme {
  padding: 24px 0px 26px;
  border-bottom: 1px solid rgb(212, 210, 210);
  display: none;
  align-items: center;
}
.menu-mobile__theme-recap-title {
  font-size: 13px;
}
.menu-mobile__theme-recap-type {
  font-size: 11px;
  color: rgb(143, 143, 143);
}
.menu-mobile__theme-light {
  margin-left: auto;
  gap: 16px;
  display: flex;
}
.menu-mobile__theme-light > div {
  cursor: pointer;
}
.main-header .menu-mobile .menu-mobile__submenu.selected ul li ul {
  display: none;
}
.main-header .language-dropdown {
  color: var(--main-color);
  margin-top: 32px;
  display: none;
  position: relative;
  align-items: center;
  margin-bottom: 24px;
}
.main-header .language-dropdown select {
  display: block;
  padding: 0px;
  appearance: none;
  border: 0px;
  outline: 0px;
  background-color: transparent;
  position: relative;
  max-width: 100%;
  text-transform: uppercase;
  font: 11px ferrari-sans-regular;
  cursor: pointer;
}
.main-header-bar #arrow-down {
  width: 16px;
}
.main-header .language-dropdown option {
  text-transform: uppercase;
  font: 11px ferrari-sans-regular;
}
.main-header .language-dropdown img {
  width: 18px;
  height: auto;
  pointer-events: none;
  margin-left: 16px;
}
.menu-mobile__current-section {
  display: none;
}
.menu-mobile.menu-mobile-footer {
  display: none;
}
.main-header .menu-mobile a img {
  display: none;
}
header:not(.header-menu-mobile) nav ul a {
  padding-bottom: 6px;
  display: inline;
}
.main-header .menu-mobile ul li a:hover + ul {
  display: block;
}
.menu-mobile__submenu {
}
.main-header .menu-mobile__submenu {
  position: absolute;
  left: 0px;
  top: calc(100% + 1px);
  width: 100%;
  padding: 64px 0px 66px;
  background: #f7f7f7;
  display: none;
}
.menu-mobile__submenu h2 {
  margin-top: 0px;
  margin-left: 112px;
  text-align: left;
  font-size: 28px;
  text-transform: none;
  box-sizing: border-box;
  padding: 0 24px;
  line-height: 34px;
  color: #181818;
}
.main-header.desktop-show .menu-mobile .menu-mobile__submenu ul li ul {
  position: relative;
  left: calc(100% + 64px);
  top: 0px;
}
.main-header .menu-mobile .menu-mobile__submenu.selected ul.selected {
  gap: 16px;
  font-size: 13px;
  line-height: 20px;
  opacity: 1;
  margin-left: 54px;
}
.main-header .menu-mobile__submenu.selected {
  display: flex;
}
header:not(.header-menu-mobile) .menu-mobile__submenu a {
  border-left: 3px solid transparent;
  padding: 6px 16px;
  color: rgb(48, 48, 48);
}
header:not(.header-menu-mobile) .menu-mobile__submenu a:hover,
header:not(.header-menu-mobile) .menu-mobile__submenu li.selected a,
header:not(.header-menu-mobile) .menu-mobile__submenu li a.selected {
  border-left-color: rgb(218, 41, 28);
}
.main-header .hamburger-icon {
  cursor: pointer;
}
.main-header .menu-mobile__submenu ul li a {
  color: #181818;
  display: flex;
  box-sizing: border-box;
  transition: color 0.3s;
}
@media screen and (min-width: 1280px) {
  .main-header .menu-mobile__submenu ul li a.unselected {
    color: #707070;
  }
}
